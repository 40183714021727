import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import {
  DO_FETCH_PRODUCTS_REQUEST,
  DO_PAYMENT_INITIATION_REQUEST,
  DO_UPDATE_PAYMENT_METHOD_REQUEST,
} from '../../constant/products';
import {
  doFetchProductsFailure,
  doFetchProductsSuccess,
  setIsLoading,
  setSelectedPlan,
} from '../../action/products';
import { createNotification } from '../../../services/Notification';
import {
  fetchProductsRequest,
  initiatePaymentRequest,
  updatePaymentMethodRequest,
} from '../../../api/user';
import {
  LOCAL_STORAGE_CONSTANT,
  PAYMENT_INTERVAL,
} from '../../../constants/app';
import { setPaymentIsInProgress } from '../../action/common';
import { errorHandler } from '../../../utils/helpers';
import { getItem } from '../../../utils/cache';

function* fetchProducts({ payload }) {
  const { noLoader, callback, interval } = payload || {};
  if (!noLoader) {
    yield put(setIsLoading(true));
  }
  try {
    const response = yield call(fetchProductsRequest);
    const products = (response?.data?.response?.data || []).reverse();
    yield put(doFetchProductsSuccess(products));
    const selectedProduct =
      products?.find(
        product =>
          product?.attributes?.interval ===
          (interval || PAYMENT_INTERVAL.YEARLY),
      ) || products?.[0];
    yield put(setSelectedPlan(selectedProduct));
    if (!noLoader) {
      yield put(setIsLoading(false));
    } else {
      return selectedProduct;
    }
    // added logic to handle "Request Timeout Case"
    if (response?.status === 408) {
      const error = new Error('Request Timeout');
      throw error;
    }
  } catch (error) {
    yield put(doFetchProductsFailure());
    if (!noLoader) {
      yield put(setIsLoading(false));
    }
    createNotification(errorHandler(error));
  }
  if (callback) callback();
}

function* initiatePayment({ payload }) {
  yield put(setPaymentIsInProgress(true));
  yield put(setIsLoading(true));
  const { callback, successCallback, errorCallBack, product_id } =
    payload || {};
  let { selectedPlan } = yield select(state => state.product);
  if (!selectedPlan && !product_id) {
    const updatedPayload = {
      noLoader: true,
      interval: payload?.interval,
    };
    selectedPlan = yield call(fetchProducts, {
      payload: updatedPayload,
    });
    if (successCallback) successCallback();
  }
  const productId = product_id || selectedPlan?.id;
  if (!productId) return;
  try {
    const data = {
      product_id: productId,
      name: payload?.name || '',
      page: payload?.page || '',
      plan_type: payload?.plan_type,
    };
    if (payload?.plan_id) {
      data.plan_id = payload.plan_id;
    }
    const userRef = getItem(LOCAL_STORAGE_CONSTANT.USER_REF_ATTRIBUTE);
    const utmSource = getItem(LOCAL_STORAGE_CONSTANT.UTM_SOURCE_ATTRIBUTE);
    const utmMedium = getItem(LOCAL_STORAGE_CONSTANT.UTM_MEDIUM_ATTRIBUTE);
    const utmCampaign = getItem(LOCAL_STORAGE_CONSTANT.UTM_CAMPAIGN_ATTRIBUTE);
    const adId = getItem(LOCAL_STORAGE_CONSTANT.UTM_AD_ID);
    const pixelId = getItem(LOCAL_STORAGE_CONSTANT.UTM_PIXEL_ID);
    const utmContent = getItem(LOCAL_STORAGE_CONSTANT.UTM_CONTENT_ATTRIBUTE);
    const fbClid = getItem(LOCAL_STORAGE_CONSTANT.FB_CLID);
    if (payload.type) {
      data.type = payload.type;
    }
    if (userRef) {
      data.ref = userRef;
      // removeItem(LOCAL_STORAGE_CONSTANT.USER_REF_ATTRIBUTE);
    }
    if (utmSource) {
      data.utm_source = utmSource;
      // removeItem(LOCAL_STORAGE_CONSTANT.UTM_SOURCE_ATTRIBUTE);
    }
    if (utmMedium) {
      data.utm_medium = utmMedium;
      // removeItem(LOCAL_STORAGE_CONSTANT.UTM_MEDIUM_ATTRIBUTE);
    }
    if (utmCampaign) {
      data.utm_campaign = utmCampaign;
      // removeItem(LOCAL_STORAGE_CONSTANT.UTM_CAMPAIGN_ATTRIBUTE);
    }
    if (utmContent) {
      data.utm_content = utmContent;
      // removeItem(LOCAL_STORAGE_CONSTANT.UTM_CONTENT_ATTRIBUTE);
    }
    if (fbClid) {
      data.fbclid = fbClid;
      // removeItem(LOCAL_STORAGE_CONSTANT.FB_CLID);
    }
    if (adId) {
      data.ad_id = adId;
      // removeItem(LOCAL_STORAGE_CONSTANT.UTM_AD_ID);
    }
    if (pixelId) {
      data.pixel = pixelId;
      // removeItem(LOCAL_STORAGE_CONSTANT.UTM_PIXEL_ID);
    }
    const response = yield call(initiatePaymentRequest, data);
    const url = response?.data?.response?.data?.url || '';
    window.location.assign(url);
    // removeItem('selectedPlan');
  } catch (error) {
    yield put(setPaymentIsInProgress(false));
    if (errorCallBack) errorCallBack();
    createNotification(errorHandler(error));
  }
  if (callback) callback();
  yield put(setIsLoading(false));
}

function* updatePaymentMethod({ payload }) {
  yield put(setIsLoading(true));
  try {
    const data = {
      name: payload?.name || '',
      page: payload?.page || '',
    };
    const response = yield call(updatePaymentMethodRequest, data);
    const url = response?.data?.response?.data?.url || '';
    window.location.assign(url);
  } catch (error) {
    createNotification(errorHandler(error));
  }
  yield put(setIsLoading(false));
}

/**
 * Watch user actions
 * */
export default function* products() {
  yield all([
    takeLatest(DO_FETCH_PRODUCTS_REQUEST, fetchProducts),
    takeLatest(DO_PAYMENT_INITIATION_REQUEST, initiatePayment),
    takeLatest(DO_UPDATE_PAYMENT_METHOD_REQUEST, updatePaymentMethod),
  ]);
}
