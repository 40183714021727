import React, { useEffect } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { capitalCase } from 'change-case';
import useWindowWidth from '../../hooks/useWindowWidth';
import {
  formatCurrency,
  getDisplayType,
  getPercentageSaved,
  localeCompare,
} from '../../utils/helpers';
import { PAYMENT_INTERVAL, TEXT_CONSTANT } from '../../constants/app';
import { setSelectedPlan } from '../../store/action/products';
import { setItem } from '../../utils/cache';

const SubscriptionSelector = () => {
  const { products, selectedPlan } = useSelector(state => state.product);
  const dispatch = useDispatch();
  const displayType = getDisplayType(useWindowWidth());
  const isMobileScreen = displayType === 'mobile';
  const isIpadScreen = displayType === 'ipad';
  const handlePlanSelect = plan => {
    dispatch(setSelectedPlan(plan));
    setItem('selectedPlan', JSON.stringify(plan));
  };
  const offValue = getPercentageSaved(products);
  const yearlyPlan = products?.find(
    product => product?.attributes?.interval === PAYMENT_INTERVAL.YEARLY,
  );

  const getLabel = interval => {
    if (interval === PAYMENT_INTERVAL.MONTHLY) {
      return 'Monthly';
    } else if (interval === PAYMENT_INTERVAL.YEARLY) {
      return 'Yearly';
    }
    return interval;
  };
  useEffect(() => {
    const plan = products?.find(
      product =>
        product?.attributes?.interval === selectedPlan?.attributes?.interval,
    );
    setItem('selectedPlan', JSON.stringify(plan));
  }, [products, selectedPlan]);

  return (
    <div className="subscription-selector">
      <span className="s-d-t">{TEXT_CONSTANT.SELECT_PREFERRED_PLAN}</span>
      <div
        className={clsx(
          isMobileScreen || isIpadScreen
            ? 'd-block justify-content-between subscription-detail-boxs'
            : 'd-flex justify-content-between subscription-detail-boxs',
        )}
      >
        {(products || [])
          .sort((a, b) =>
            localeCompare(b?.attributes?.interval, a?.attributes?.interval),
          )
          .map(plan => (
            <div
              key={plan.id}
              onClick={() => handlePlanSelect(plan)}
              className={clsx(
                'plan-radio-group position-relative',
                selectedPlan?.id === plan.id && 'selected-plan',
              )}
            >
              <Form.Check
                htmlFor={capitalCase(getLabel(plan?.attributes?.interval))}
                checked={selectedPlan?.id === plan.id}
                value={plan.id}
                type="radio"
                onChange={() => handlePlanSelect(plan)}
              />
              <div className="month-price">
                <div className="d-flex justify-content-between type-price">
                  <h5 className="interval">
                    {capitalCase(getLabel(plan?.attributes?.interval))}
                  </h5>
                  <div className="cut-price-div">
                    {plan.attributes.interval === 'year' && (
                      <h5 className="cut-price me-2">
                        {formatCurrency(300, plan.attributes.currency, 2)}
                      </h5>
                    )}
                    <h5 className="amount">
                      {formatCurrency(
                        +plan.attributes.amount_in_cents / 100,
                        plan.attributes.currency,
                      )}
                    </h5>
                  </div>
                </div>
                <span>{plan.attributes.description}</span>
              </div>
              {yearlyPlan?.id === plan.id && offValue ? (
                <div className="off_tag">
                  <span>{offValue}</span>% off
                </div>
              ) : (
                ''
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default SubscriptionSelector;
